<template>
  <b-sidebar
    :id="'add-new-user-sidebar' && expanded ? 'mein-expanded-view' : ' '"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    :no-close-on-backdrop="
      $refs.refFormObserver && $refs.refFormObserver.flags.dirty
    "
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="fixed-sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ title }}
        </h5>
        <div>
          <b-tooltip
            v-if="isEditing"
            title="Full Page View"
            class="cursor-pointer"
            :target="`full-page-view-${value.id}`"
          />
          <feather-icon
            v-if="isReadonly"
            :target="`edit-view-${value.id}`"
            class="ml-1 cursor-pointer"
            icon="EditIcon"
            size="16"
            @click="editTab(value)"
          />
          <feather-icon
            :target="`expand-minimize-view`"
            class="ml-1 cursor-pointer"
            :icon="expanded ? 'MinimizeIcon' : 'MaximizeIcon'"
            size="16"
            @click="sideResize"
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2 add-edit-sidebar-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Full Name"
                rules="required"
              >
                <b-form-group v-if="isReadonly" label="Full Name">
                  <b-list-group>
                    <b-list-group-item class="input-wrap">
                      {{ form.name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                  class="first-required-field"
                  label-for="name"
                >
                  <label
for="name"
class="label-edit">Full Name (<span>*</span> <label>required</label> )</label>
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :plaintext="isReadonly"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Email -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group v-if="isReadonly" label="Email">
                  <b-list-group>
                    <b-list-group-item class="input-wrap">
                      {{ form.peopleHandle }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                  class="required-field"
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.peopleHandle"
                    :state="getValidationState(validationContext)"
                    :plaintext="isReadonly"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Description -->
          <b-row>
            <b-col md="12">
              <validation-provider name="Description">
                <label class="label-edit">Description</label>
                <Quill-Editor
                  id="user-add-editor"
                  :description.sync="form.description"
                  :is-readonly.sync="disabled"
                  @assign="changeDesc"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Role -->
          <b-row>
            <b-col :md="expanded ? 12 : 12">
              <validation-provider #default="validationContext" name="Role">
                <b-form-group v-if="isReadonly" label="Roles & Processes">
                  <b-button
                    v-if="fieldLoading"
                    variant="primary"
                    disabled
                    class="mr-1 loadingBtn"
                  >
                    <b-spinner small />
                    <span>Loading...</span>
                  </b-button>
                  <b-list-group>
                    <b-list-group-item
                      v-if="
                        fieldLoading === false &&
                          (!user.roles || user.roles.length === 0)
                      "
                      class="input-wrap text-danger"
                    >
                      No Roles Assigned
                    </b-list-group-item>
                  </b-list-group>
                  <div v-if="!fieldLoading">
                    <b-card
                      v-for="(role, index) in user.roles"
                      :key="role.roleHandle"
                      style="text-align: center"
                      class="card-custom"
                    >
                      <div class="card-header btn-grp">
                        <b-button
                          v-b-toggle
                          class="tab-btn"
                          variant="light"
                          block
                          :href="`#collapse-${index}`"
                          @click.prevent="isVisible(index)"
                        >
                          <b-row>
                            <b-col cols="1" class="icon-container">
                              <feather-icon
                                :icon="
                                  values[index]
                                    ? 'ChevronUpIcon'
                                    : 'ChevronDownIcon'
                                "
                                class="icon-left"
                                size="16"
                                @click="dropIco"
                              />
                            </b-col>
                            <b-col cols="10" class="truncate role-title">
                              <span>{{
                                '  ' + role.roleHandle + ': ' + role.name
                              }}</span>
                            </b-col>
                            <b-col cols="1" class="link__">
                              <feather-icon
                                class="cursor-pointer"
                                icon="ExternalLinkIcon"
                                size="16"
                                @click="gotoRole(role)"
                              />
                            </b-col>
                          </b-row>
                        </b-button>
                      </div>
                      <!-- CARD BODY -->
                      <div class="card-body">
                        <b-collapse
                          :id="`collapse-${index}`"
                          v-model="values[index]"
                          visible
                        >
                          <b-table
                            v-if="rolesItems(role)"
                            show-empty
                            hover
                            empty-text="No matching records found"
                            :items="rolesItems(role)"
                            mb-0
                            :fields="fields"
                            table-variant="light-secondary"
                            class="custom-table"
                            fixed
                            @row-clicked="gotoProcess"
                          >
                            <template #cell(name)="data">
                              <span
                                v-if="data.item.name === 'Not Assigned'"
                                style="color: red"
                              >
                                {{ data.item.name }}
                              </span>
                              <span
                                v-else
                                style="
                                  overflow-wrap: anywhere;
                                  text-align: left;
                                  float: left;
                                "
                              >
                                {{
                                  data.item.processHandle +
                                    ': ' +
                                    data.item.name
                                }}
                              </span>
                            </template>

                            <template #cell(state)="data">
                              <div class="">
                                <b-badge
                                  v-if="data.item.name !== 'Not Assigned'"
                                  pill
                                  :variant="
                                    data.item.owned && data.item.assigned
                                      ? 'primary'
                                      : data.item.assigned
                                        ? 'dark'
                                        : 'success'
                                  "
                                >
                                  {{
                                    data.item.owned && data.item.assigned
                                      ? 'Owner & Assignee'
                                      : data.item.assigned
                                        ? 'Assignee only'
                                        : 'Owner only'
                                  }}
                                </b-badge>
                              </div>
                            </template>
                          </b-table>
                          <div v-else
class="msg"
>no Process assigned</div>
                        </b-collapse>
                      </div>
                    </b-card>
                  </div>
                  <!-- <b-list-group>
                    <b-list-group-item
                      v-for="option in listGroupItems(form.roleIds, roleOptions)"
                      :key="option.value"
                      class="input-wrap hyperlink-underlined"
                      :class="option.label === 'Not Assigned' ? 'text-danger' : 'cursor-pointer'"
                      @click="option.label === 'Not Assigned' ? null : gotoRole(option)"
                    >
                      {{ option.label }}
                    </b-list-group-item>
                  </b-list-group> -->
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                  label="Role"
                  label-for="role"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="form.roleIds"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :disabled="isReadonly"
                    multiple
                    class="wrapped-li"
                    input-id="role"
                    append-to-body
                    :calculate-position="withPopper"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- User Type -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="User Type"
                rules="required"
              >
                <b-form-group v-if="isReadonly" label="User Type">
                  <b-list-group>
                    <b-list-group-item
                      v-for="option in listGroupItems(
                        [form.userType],
                        userTypeOptions
                      )"
                      :key="option.value"
                      class="input-wrap"
                      :class="
                        option.label === 'Not Assigned' ? 'text-danger' : ''
                      "
                    >
                      {{ option.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                  class="required-field"
                  label="User Type"
                  label-for="user-type"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="form.userType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userTypeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :disabled="isReadonly"
                    class="wrapped-li"
                    input-id="user-type"
                    append-to-body
                    :calculate-position="withPopper"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Auth Method -->
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Auth Method"
                rules="required"
                class="required-field"
              >
                <b-form-group v-if="isReadonly" label="Auth Method">
                  <b-list-group>
                    <b-list-group-item
                      v-for="option in listGroupItems(
                        [form.authMethod],
                        authMethodOptions
                      )"
                      :key="option.value"
                      class="input-wrap"
                      :class="
                        option.label === 'Not Assigned' ? 'text-danger' : ''
                      "
                    >
                      {{ form.authMethod }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                  label="Auth Method"
                  label-for="auth-method"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="form.authMethod"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="authValidate(authMethodOptions)"
                    :reduce="val => val.value"
                    :clearable="false"
                    :disabled="isReadonly"
                    class="wrapped-li"
                    input-id="auth-method"
                    append-to-body
                    :calculate-position="withPopper"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Set Password Method -->
            <b-col
              v-if="form.authMethod === 'Email' && !isEditing"
              :md="expanded ? 6 : 12"
            >
              <validation-provider
                #default="validationContext"
                name="Set Password Method"
                rules="required"
              >
                <b-form-group
                  label="Set Password Method"
                  label-for="set-password-method"
                  :state="getValidationState(validationContext)"
                  style="margin-top: 0.6rem"
                >
                  <div class="demo-inline-spacing" style="margin-top: -16px">
                    <b-form-radio
                      v-model="setPasswordMethod"
                      name="set-password-method-radios"
                      value="setPassword"
                    >
                      Set Password
                    </b-form-radio>
                    <b-form-radio
                      v-model="setPasswordMethod"
                      name="set-password-method-radios"
                      value="sendInvitationEmail"
                    >
                      Send Invitation Email
                    </b-form-radio>
                  </div>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--Set New Password -->
            <b-col
              v-if="form.authMethod === 'Email' && isEditing"
              class="mt-2 mb-1"
              :md="expanded ? 6 : 12"
            >
              <validation-provider
                v-if="form.authMethod === 'Email' && isEditing"
                #default="validationContext"
                name="Set Password Method"
                rules="required"
              >
                <b-form-group
                  v-if="!isReadonly"
                  label-for="set-password-method"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-checkbox
                    v-model="setNewPasswordChecked"
                    :checked="setNewPasswordChecked"
                    name="check-button"
                    switch
                    inline
                  >
                    Set New Password
                  </b-form-checkbox>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Password -->
            <b-col
              v-if="
                form.authMethod === 'Email' &&
                  !isReadonly &&
                  (setPasswordMethod === 'setPassword' || setNewPasswordChecked)
              "
              :md="expanded ? 6 : 12"
            >
              <validation-provider
                v-if="
                  form.authMethod === 'Email' &&
                    !isReadonly &&
                    (setPasswordMethod === 'setPassword' || setNewPasswordChecked)
                "
                #default="validationContext"
                name="Password"
                :rules="!isEditing ? 'required' : ''"
              >
                <b-form-group label="Password" label-for="password">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      :state="getValidationState(validationContext)"
                      :type="passwordType"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="viewPasswordIcon"
                        class="cursor-pointer"
                        @click="togglePasswordType"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Receive Weekly Reminders -->
            <b-col md="12">
              <validation-provider
                #default="validationContext"
                name="ReceiveWeeklyReminders"
              >
                <b-form-group
                  v-if="isReadonly"
                  label="Receive Weekly Reminders?"
                >
                  <b-form-checkbox
                    :checked="form.receiveWeeklyReminders"
                    name="check-button"
                    switch
                    inline
                    disabled
                  >
                    Yes
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                  label="Receive Weekly Reminders?"
                  label-for="receiveWeeklyReminders"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-checkbox
                    v-model="form.receiveWeeklyReminders"
                    :checked="form.receiveWeeklyReminders"
                    name="check-button"
                    switch
                    inline
                  >
                    Yes
                  </b-form-checkbox>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <b-row>
            <b-col md="12">
              <div
                class="mt-2 fixed-footer-form-actions"
                :class="{ 'd-flex': isReadonly || !isEditing }"
              >
                <div v-if="!isReadonly">
                  <div v-if="isEditing" class="d-flex justify-content-between">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      @click="hide"
                    >
                      Cancel
                    </b-button>
                    <div>
                      <b-button
                        v-for="el in btns"
                        :key="el"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :variant="el === 'Save' ? 'outline-primary' : 'primary'"
                        :class="{ 'mr-2 ': el === 'Save' }"
                        type="submit"
                        :disabled="isLoading"
                        @click="submitState = el"
                      >
                        {{ el }}
                      </b-button>
                    </div>
                  </div>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    :disabled="isLoading"
                  >
                    Add
                  </b-button>
                </div>
                <b-button
                  v-if="isReadonly || !isEditing"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BTooltip,
    VBTooltip,
    BCard,
    VBToggle,
    VBVisible,
    BTable,
    BCollapse,
    BBadge,
    BSpinner,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, alphaNum, email } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import { mapActions, mapState } from 'vuex'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { createPopper } from '@popperjs/core'
  import countries from '@/@fake-db/data/other/countries'
  import firebase from '@/libs/firebase'
  import QuillEditor from '@/views/shared/QuillEditor.vue'

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BInputGroup,
      BInputGroupAppend,
      BFormRadio,
      BFormCheckbox,
      vSelect,
      BListGroup,
      BListGroupItem,
      BRow,
      BCol,
      BTooltip,
      QuillEditor,
      BCard,
      BTable,
      BCollapse,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BBadge,
      BSpinner,
    },
    directives: {
      'b-toggle': VBToggle,
      'b-visible': VBVisible,
      Ripple,
      'b-tooltip': VBTooltip,
    },
    model: {
      prop: 'isSidebarActive',
      event: 'update:is-sidebar-active',
    },
    props: {
      isExpanded: {
        type: Boolean,
      },
      isSidebarActive: {
        type: Boolean,
        required: true,
      },
      isReadonly: {
        type: Boolean,
      },
      userTypeOptions: {
        type: Array,
        required: true,
      },
      authMethodOptions: {
        type: Array,
        required: true,
      },
      value: {
        type: Object,
        default: () => this.getDefaultForm(),
      },
      editTab: {
        type: Function,
        default: () => this.editTab(this.value),
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
        form: this.getDefaultForm(),
        passwordType: 'password',
        setPasswordMethod: '',
        setNewPasswordChecked: false,
        btns: ['Save', 'Save & Close'],
        submitState: '',
        expanded: false,
        disabled: this.isReadonly,
        collapseType: 'margin',
        collType: false,
        show: 'open',
        values: [],
        fields: [
          // { key: 'ID' },
          { key: 'Name', label: 'Process Name', class: 'table-th' },
          {
            key: 'State',
            label: 'OWNER/ASSIGNEE',
            thStyle: { width: '40% !important' },
            class: 'table-th',
          },
        ],
        processArray: [],
        fieldLoading: false,
      }
    },
    computed: {
      ...mapState('app', ['isLoading']),
      ...mapState('role', ['roles', 'currentUserRoles', 'allRoles']),
      ...mapState('user', ['user', 'currentUser']),
      isEditing() {
        return !!this.value
      },
      title() {
        if (this.isReadonly) {
          return 'Person Details'
        }
        this.fetchRolesIfNotLoaded()
        return this.isEditing ? 'Edit Person' : 'Add A New Person'
      },
      viewPasswordIcon() {
        return this.passwordType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      roleOptions() {
        if (this.$route.name === 'user-list') {
          return this.allRoles.nodes.map(item => ({
            label: item.roleHandle,
            value: item.id,
            ...item,
          }))
        }
        return this.currentUserRoles.nodes.map(item => ({
          label: item.roleHandle,
          value: item.id,
        }))
      },
      // isExpanded_prop() {
      //   if (this.isExpanded) {
      //     this.expanded = true
      //   }
      //   return this.isExpanded
      // },
    },
    watch: {
      async value(value) {
        if (value) {
          if (value && value.id) {
            if (value.roles) {
              this.fieldLoading = true
            }
            this.getUser({
              id: value.id,
            }).then(() => {
              this.form = { ...value, ...this.user }
              this.fieldLoading = false
            })
          }
          this.form = await {
            ...value,
            roles: undefined,
            __typename: undefined,
          }
          if (value.roles) {
            this.value.roles.forEach((el, index) => {
              this.values[index] = true
            })
          }
        }
        this.expanded = this.isExpanded
      },
      isReadonly() {
        this.disabled = this.isReadonly
      },
      isExpanded() {
        this.expanded = this.isExpanded
      },
    },
    mounted() {
      if (this.$route.name === 'user-list') {
        this.fetchRolesIfNotLoaded()
      }
      if (this.$route.name === 'my-profile') {
        this.refetchCurrentUserRolesIfNotLoaded()
      }
    },
    methods: {
      ...mapActions('role', [
        'fetchRolesIfNotLoaded',
        'refetchCurrentUserRolesIfNotLoaded',
      ]),
      ...mapActions('user', ['getUser']),
      isVisible(index) {
        const elementClass = document.getElementById(
          `collapse-${index}`
        ).classList
        if (elementClass.value.includes('show')) {
          this.values[index] = true
        } else {
          this.values[index] = false
        }
      },
      authValidate(values) {
        if (this.currentUser.id === this.form.id) {
          return values.filter(el => el.value !== 'No')
        }
        return values
      },
      dropIco() {
        this.dropState = !this.dropState
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      gotoRole(item) {
        const route = this.$router.resolve({
          path: '/roles',
          query: {
            q: `roleHandle=${item.roleHandle}`,
            qd: `id=${item.id}`,
            compact: true,
          },
        })
        window.open(route.href, '_blank')
      },
      gotoProcess(item) {
        const route = this.$router.resolve({
          path: '/processes',
          query: {
            q: item.processHandle,
            qd: item.processHandle,
            compact: true,
          },
        })
        window.open(route.href, '_blank')
      },
      sideResize() {
        this.expanded = !this.expanded
      },
      openRole(option) {
        // TODO: the openRole function will change in the future
        this.$emit('update:is-sidebar-active', false)
        this.$emit('open-role', option)
      },
      changeDesc(value) {
        this.form.description = value
      },
      resetForm() {
        this.$emit('update:value', this.getDefaultForm())
        this.$nextTick(() => {
          this.$refs.refFormObserver.reset()
        })
        this.form = this.getDefaultForm()
        this.setNewPasswordChecked = false
        this.setPasswordMethod = ''
      },
      displayProfile(item) {
        this.$router.push({ path: `/userProfile/${item.id}` })
      },
      onSubmit() {
        this.form.roles = undefined
        const action = this.isEditing
          ? this.$store.dispatch('user/updateUser', this.form)
          : this.$store.dispatch('user/addUser', this.form)

        action
          .then(() => {
            if (this.setPasswordMethod === 'sendInvitationEmail') {
              firebase
                .auth()
                .sendPasswordResetEmail(this.form.peopleHandle)
                .then(() => {
                  if (this.submitState === 'Save') {
                    this.$emit('update:is-sidebar-active', true)
                    this.$emit('update:is-readonly', true)
                  } else {
                    this.$emit('update:is-sidebar-active', false)
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Password reset email was sent successfully',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Password reset email was sent successfully!',
                    },
                  })
                })
                .catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Failed to send password reset email',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: error.message,
                    },
                  })
                })
            } else if (this.submitState === 'Save') {
              this.$emit('update:is-sidebar-active', true)
              this.$emit('update:is-readonly', true)
            } else {
              this.$emit('update:is-sidebar-active', false)
            }
          })
          .then(() => {
            this.$store.dispatch('user/getUsers', {})
          })
      },
      getDefaultForm() {
        this.expanded = false
        return {
          name: '',
          peopleHandle: '',
          description: '',
          userType: null,
          authMethod: null,
          password: null,
          roleIds: null,
          receiveWeeklyReminders: true,
        }
      },
      togglePasswordType() {
        this.passwordType =
          this.passwordType === 'password' ? 'text' : 'password'
      },
      listGroupItems(ids, array) {
        if (ids && ids[0] != null && ids.length > 0) {
          const items = ids.map(id => array.find(el => el.value === id))
          return items
        }
        const item = [{ label: 'Not Assigned', value: `${Math.random()}` }]
        return item
      },
      withPopper(dropdownList, component, { width }) {
        // eslint-disable-next-line no-param-reassign
        dropdownList.style.width = width

        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1],
              },
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn({ state }) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              },
            },
          ],
        })

        return () => popper.destroy()
      },
      rolesItems(role) {
        if (
          role.assignedProcesses?.length === 0 &&
          role.process?.length === 0
        ) {
          const item = [{ name: 'Not Assigned', value: `${Math.random()}` }]
          return item
        }
        const allProcesses = this.combineArrays(
          role.assignedProcesses,
          role.process
        ).filter(item => !!item)
        const ids = []
        const processes = []
        allProcesses.forEach(item =>
          ids.includes(item.id) ? null : ids.push(item.id)
        )

        allProcesses.forEach(item => {
          const found = processes.find(({ id }) => id === item.id)
          if (!found) {
            processes.push(item)
          }
        })
        processes.forEach((element, index) => {
          const owned = role.process?.find(pr => pr.id === element.id)
          const assigned = role.assignedProcesses?.find(
            aPr => aPr.id === element.id
          )
          if (owned) {
            processes[index].owned = 'OWNED'
          }
          if (assigned) {
            processes[index].assigned = 'ASSIGNED'
          }
        })
        return processes
      },
      combineArrays(arr1, arr2) {
        if (arr1 && arr2) {
          const allArr = []
          arr1.forEach(el => {
            const arr2El = arr2.find(item => item.id === el.id)
            if (arr2El) {
              allArr.push({ ...el, ...arr2El })
            } else {
              allArr.push(el)
            }
          })
          arr2.forEach(el => {
            const arr2El = allArr.find(item => item.id === el.id)
            if (!arr2El) {
              allArr.push(el)
            }
          })
          return allArr
        }
        const newArr1 = [arr1, []]
        const newArr2 = [arr2, []]

        return newArr1.concat(newArr2)
      },
    },
  }
</script>

<style lang="scss">
  @import '../../@core/scss/vue/libs/vue-select';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }

  .required-field {
    label::after {
      content: '*';
      color: #ea5455;
      font-weight: bold;
      font-size: larger;
      margin-left: 2px;
    }
  }

  .first-required-field {
    span {
      color: #ea5455;
      font-weight: bold;
      font-size: larger;
    }
  }

  .form-group {
    label {
      font-size: 1rem;
    }
  }

  .text-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    padding: 14px;
  }

  #mein-expanded-view {
    width: 80% !important;
  }
  .fixed-sidebar-header {
    position: fixed;
    width: 100%;
    z-index: 10000;
  }

  .add-edit-sidebar-form {
    margin-top: 40px;
    margin-bottom: 52px;
    padding-top: 12px;
  }

  .fixed-footer-form-actions {
    position: fixed;
    bottom: 0;
    padding: 16px 58px 16px 0;
    width: 100%;
    background-color: white;
    z-index: 10000;
  }
  .input-wrap {
    overflow-wrap: break-word;
  }
  .card-custom > .card-body {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
    border-radius: 5px;
    padding: 5px;
    .card-body {
      padding: 0px;
    }
  }
  .card-custom > .card-header {
    padding: 0px;
  }
  .card-title {
    text-align: left;
  }

  .card-custom {
    min-width: 100% !important;
    align-self: center;
  }
  .tab-btn {
    text-align: center;
    color: black !important;
    background-color: white !important;
    border: none;
    animation-play-state: paused !important;
    box-shadow: none !important;
  }
  .btn-grp {
    width: 100%;
    padding: 0px !important;
  }
  .icon-left {
    float: left;
  }
  .icon-container {
    padding-left: 0px;
  }
  .table-th {
    padding: 10.08px 0px !important;
    text-align: left;
    div {
      margin-left: 5px;
    }
    span {
      margin-left: 5px;
    }
  }
  td.table-th {
    text-align: center;
    .badge {
      text-align: center !important;
    }
  }
  .loadingBtn {
    width: 100%;
    span {
      margin-left: 10px;
    }
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .role-title {
    text-align: left;
    padding-left: 0px;
  }
  .link__ {
    padding-left: 0px !important;
  }
</style>
